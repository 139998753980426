import React from "react"

import { Scrollbars } from "react-custom-scrollbars"
import "../utils/css/components/scrolls.css"
class Scroller extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div id="site-wrapper" className="site-wrapper">
        <Scrollbars
          autoHide
          autoHideTimeout={2000}
          renderView={props => <div {...props} className="track-render" />}
          style={{
            height: "100vh",
            width: "100vw",
          }}
          renderTrackHorizontal={props => <div {...props} />}
          renderThumbHorizontal={props => <div {...props} />}
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbVertical={props => (
            <div {...props} className="thumb-vertical" />
          )}
          autoHeight={false}
          thumbMinSize={25}
        >
          {children}
        </Scrollbars>
      </div>
    )
  }
}

export default Scroller
