/** Layout V2 */

import React from "react"

import Header from "../partials/header"
import Footer from "../partials/footer"
import Scroller from "../scrollbar"
// CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "../../utils/css/components/scrolls.css"
class Layout extends React.Component {
  render() {
    const { children, headerActive, location, showFooter = true } = this.props
    return (
      <Scroller>
        <Header headerActive={headerActive} location={location}></Header>
        <main id="site-main" className="site-main">
          <div id="swup">{children}</div>
        </main>
        {showFooter ? (
          <Footer headerActive={headerActive} location={location} />
        ) : (
          <></>
        )}
      </Scroller>
    )
  }
}

export default Layout
