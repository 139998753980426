import React from "react"

export class FormGroup extends React.Component {
    render (){
        const {children} = this.props;
        return <div className="form-group">{children}</div>
    }
}

export class FormGroupLabel extends React.Component {
    render (){
        const {className, children} = this.props;
        return <label className={className}>{children}</label>
    }
}

export class FormGroupInput extends React.Component{
    constructor (props){
        super(props);

        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    handleOnFocus = event =>{
        const labelElement = event.target.parentElement;
        labelElement.classList.add("label--on-focus");
    }   

    handleOnBlur = event =>{
        const labelElement = event.target.parentElement, {value, onBlur} = this.props;

        if(value.trim() === "")
            labelElement.classList.remove("label--on-focus");

        if(onBlur){
            onBlur();
        }
    }   

    render() {
        const {labelClassName, labelName, inputClassName, type, name, value, onChange, disabled} = this.props;

        return <FormGroup>
            <FormGroupLabel className={labelClassName}>
                <span>{labelName}</span>
                <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    className={inputClassName}
                    disabled={disabled}
                />
            </FormGroupLabel>
        </FormGroup>
    }
}

export class FormGroupTextarea extends React.Component{
    constructor (props){
        super(props);

        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    handleOnFocus = event =>{
        const labelElement = event.target.parentElement;
        labelElement.classList.add("label--on-focus");
    }   

    handleOnBlur = event =>{
        const labelElement = event.target.parentElement, {value, onBlur} = this.props;

        if(value.trim() === "")
            labelElement.classList.remove("label--on-focus");

        if(onBlur){
            onBlur();
        }
    }   

    render() {
        const {labelClassName, labelName, inputClassName, name, value, onChange, rowCount = 5, disabled} = this.props;

        return <FormGroup>
            <FormGroupLabel className={labelClassName}>
                <span>{labelName}</span>
                <textarea
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={inputClassName}
                    rows={rowCount}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    disabled={disabled}
                />
            </FormGroupLabel>
        </FormGroup>
    }
}

export class FormGroupSubmit extends React.Component{
    render() {
        const {className, children, submitMessage = "", disabled=false} = this.props;
        return <FormGroup>
            {
                disabled ? <button type="submit" className={className} disabled>{children}</button> : <button type="submit" className={className}>{children}</button>
            }
            
            <span className="submit-btn--message">{submitMessage}</span>
        </FormGroup>
    }
}

export class FormRow extends React.Component {
    render (){
        const {children, className, singleColumn = false} = this.props; 
        return <div className={`row${className ? " " + className : ""}`}>{singleColumn ? <FormColumn>{children}</FormColumn> : children}</div>
    }
}

export class FormColumn extends React.Component {
    render () {
        const {children, width = "12"} = this.props;
        return <div className={`col-sm-${width}`}>{children}</div>
    }
}

export const FormItemBuilder = ({label, type, name, value, onChange, disabled}) => {
    // <FormGroupTextarea labelName={label} type={type} name={`${key}_${name}`} value={FormValues[key][name]} onChange={this.handleInputChange}/>

    let FormItem = <></>;
    if(type === "textarea"){
        FormItem = <FormGroupTextarea labelName={label} type={type} name={name} value={value} onChange={onChange} disabled={disabled}/>
    }
    else if (type === "text"){
        FormItem =  <FormGroupInput labelName={label} type={type} name={name} value={value} onChange={onChange} disabled={disabled}/>
    }

    return <div className="admin-page-form--item">{FormItem}</div>;
}