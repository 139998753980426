import React from "react"
import ReactResizeDetector from "react-resize-detector"
import * as Scroll from "react-scroll"
import { Link, navigate, StaticQuery, graphql } from "gatsby"

import SiteLogo from "../../../content/assets/prosource-dark.svg"
import { responsive } from "../../utils/constants"

import { PLink, FooterCard } from "./base"

let scroll = Scroll.animateScroll

function HandleResize(width, height) {
  const { mobile, tablet } = responsive
  if (width >= tablet) {
    return { isMobile: false, isTablet: false, isDesktop: true }
  } else if (width >= mobile && width <= tablet) {
    return { isMobile: false, isTablet: true, isDesktop: false }
  } else {
    return { isMobile: true, isTablet: false, isDesktop: false }
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
      isTablet: false,
      isDesktop: true,
    }

    this.handleFooterLinkClick = this.handleFooterLinkClick.bind(this)
  }

  onResize = (width, height) => {
    this.setState(HandleResize(width, height))
  }

  handleFooterLinkClick = (event) => {
    event.preventDefault()
    const { headerActive } = this.props
    const navigateLinkText = event.currentTarget.dataset.text,
      navigateLink = event.currentTarget.getAttribute("href"),
      SiteWrapper = document.getElementById("site-wrapper")

    if (navigateLink) {
      if (headerActive === navigateLinkText) {
        scroll.scrollToTop()
      } else {
        if (SiteWrapper) SiteWrapper.classList.add("animated", "fadeOut")
        setTimeout(() => {
          navigate(navigateLink)
        }, 1000)
      }
    }
  }

  render() {
    const {
      data: {
        contentNode: { values = "" },
      },
    } = this.props

    const {
      third_column = [],
      sections = [],
      first_column = [],
      second_column = [],
      socialmedia = [],
    } = JSON.parse(values)

    const { isMobile = false } = this.state

    /* Footer Parts */

    const CopyrightDiv = (
      <>
        <div>
          {" "}
          &copy; {new Date().getFullYear()} ProSource BPO &#183; All rights
          reserved.
        </div>
        <span> Made in Manila &#183; By Developers For Developers</span>
      </>
    )

    const SectionsDiv = (
      <div className="footer-section-div">
        {sections.map(({ text, link }) => {
          const tlink = link === "/" ? "/" : "/" + link
          return (
            <Link
              to={tlink}
              onClick={this.handleFooterLinkClick}
              className={"p-link p-link--footer"}
              data-text={text}
            >
              {" "}
              {text}{" "}
            </Link>
          )
        })}
      </div>
    )

    const SocialMediaDiv = (
      <div className="footer-social-div">
        {socialmedia.map(({ text, link }) => {
          return (
            <a href={link} className={`p-link p-link--footer`}>
              {text}
            </a>
          )
        })}
      </div>
    )

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
        <footer
          className={`site-foot${isMobile ? " site-foot-mobile" : ""}`}
          id="site-foot"
        >
          <div className="footer-a scroller-adjustment">
            <div>
              <FooterCard>
                <img
                  src={SiteLogo}
                  alt="Logo"
                  className="site-head-logo"
                  style={{ height: "20px" }}
                />
              </FooterCard>
              <div className="footer-a--locations">
                {first_column.map(({ country, address }) => {
                  return <FooterCard cardTitle={country}>{address}</FooterCard>
                })}
              </div>
            </div>

            <div className="footer-a--contact">
              {second_column.map(({ title, info, email }) => {
                return (
                  <FooterCard cardTitle={title}>
                    {/* {info ? info : ""} */}
                    {email ? (
                      <span>
                        <a
                          href={`mailto:${email}`}
                          className={`p-link p-link--hover-dashed`}
                        >
                          {email}
                        </a>
                      </span>
                    ) : (
                      ""
                    )}
                  </FooterCard>
                )
              })}
            </div>

            <div>
              <FooterCard
                cardTitle={`Expertise`}
                style={{ marginBottom: `0px` }}
              ></FooterCard>
              <div className="footer-card expertise-footer-card">
                {third_column.map(({ text, link }) => {
                  const tlink = link === "/" ? "/" : link
                  return (
                    <PLink
                      to={tlink}
                      className={`p-link`}
                      onClickFadeOut={true}
                      ignoreScrollTop={true}
                    >
                      {text}
                    </PLink>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="footer-hr scroller-adjustment" />

          <div className="footer-b scroller-adjustment">
            <div className="footer-b--copyright">
              <FooterCard whiteSpaceSpan="unset">{CopyrightDiv}</FooterCard>
            </div>
            <div className="footer-b--links">
              <FooterCard whiteSpaceSpan="unset">
                {" "}
                {SectionsDiv} {SocialMediaDiv}{" "}
              </FooterCard>
            </div>
          </div>
        </footer>
      </ReactResizeDetector>
    )
  }
}

const indexQuery = graphql`
  query FooterQuery {
    contentNode(identifier: { eq: "partial_footer" }) {
      identifier
      values
    }
  }
`

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <Footer location={props.location} data={data} {...props} />
    )}
  />
)
