import React from "react"
import { navigate } from "gatsby"

import { FormGroupInput, FormGroupSubmit, FormGroupTextarea } from "../form"
import GFuncRequest from "../../../utils/helpers/function-api"

import ReCAPTCHA from "react-google-recaptcha"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Button } from "react-bootstrap"

const MySwal = withReactContent(Swal)

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      work: "",
      number: "",
      captcha: "",
      showSnackbar: false,
      enableSubmit: false,
      submitMessageText: "Send Message",
    }

    this.validateForm = this.validateForm.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.captchaOnChange = this.captchaOnChange.bind(this)
  }

  validateForm = () => {
    const { name = "", email = "", work = "", captcha = "" } = this.state
    let willEnableSubmit = true
    if (name.trim() === "") {
      willEnableSubmit = false
    }

    if (email.trim() === "") {
      willEnableSubmit = false
    }

    if (work.trim() === "") {
      willEnableSubmit = false
    }

    if (captcha.trim() === "") {
      willEnableSubmit = false
    }

    this.setState({
      enableSubmit: willEnableSubmit,
    })
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const targetname = target.name

    this.setState({
      [targetname]: value,
    })

    this.validateForm()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { name, email, work, number } = this.state

    this.setState({
      showSnackbar: false,
      submitMessageText: "Sending message...",
    })
    const urlPath =
      "https://asia-southeast1-prd-pickup.cloudfunctions.net/emailFunctionPro"
    // const urlPath = "http://localhost:8080/"

    GFuncRequest.Post(
      {
        urlPath,
        reqBody: {
          name: name,
          email: email,
          body: work,
          number: number,
        },
      },
      (err, response) => {
        if (err) {
          console.log(err)
        } else {
          ;(function (l) {
            if (!l) {
              window.lintrk = function (a, b) {
                window.lintrk.q.push([a, b])
              }
              window.lintrk.q = []
            }
            var s = document.getElementsByTagName("script")[0]
            var b = document.createElement("script")
            b.type = "text/javascript"
            b.async = true
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"
            s.parentNode.insertBefore(b, s)
          })(window.lintrk)
          window.lintrk("track", { conversion_id: 5541468 })
          MySwal.fire({
            title: "Message successfully sent!",
            html: (
              <>
                <p>
                  Thank you for contacting us. We will get back to you as soon
                  as possible.{" "}
                </p>
              </>
            ),
            showConfirmButton: false,
            icon: "success",
          }).then(() => {
            this.setState({
              name: "",
              email: "",
              work: "",
              number: "",
              captcha: "",
              showSnackbar: false,
              enableSubmit: false,
              submitMessageText: "Send Message",
            })
            this.refs.recaptchaRef.reset()
          })
        }
      }
    )
  }

  handleOnBlur = (event) => {
    this.validateForm()
  }

  captchaOnChange = (value) => {
    this.setState({ captcha: value })
    this.validateForm()
  }

  render() {
    const { className } = this.props,
      { enableSubmit, submitMessageText } = this.state

    return (
      <form
        onSubmit={this.handleSubmit}
        className={`prosource-form${className ? " " + className : ""}`}
      >
        <div className="form-label">Tell us about your project</div>
        <FormGroupInput
          labelName="Tell us your name"
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupInput
          labelName="Tell us your email"
          type="text"
          name="email"
          value={this.state.email}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupInput
          labelName="Tell us your contact number"
          type="text"
          name="number"
          value={this.state.number}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupTextarea
          labelName="Tell us about the work"
          type="text"
          name="work"
          value={this.state.work}
          onChange={this.handleInputChange}
          rowCount={1}
          onBlur={this.handleOnBlur}
        ></FormGroupTextarea>

        <ReCAPTCHA
          ref={"recaptchaRef"}
          sitekey="6LfRrNwUAAAAAIqcWujLL3U3TRjVLVJXJz5UfnvY"
          onChange={this.captchaOnChange}
        />

        <FormGroupSubmit
          className={`contact-submit-message`}
          disabled={!enableSubmit}
        >
          {submitMessageText}
        </FormGroupSubmit>
      </form>
    )
  }
}
