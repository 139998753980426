import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"
import ContactForm from "../components/partials/forms/contact-form"

import "../utils/normalize.css"
import "../utils/css/v2.css"

// Partials
import {
  CardList,
  CardListItem,
  HeroTitleDiv,
} from "../components/partials/base"

function removeSpaces(str) {
  return str.replace(/ /g, "")
}

const CardListItemContent = ({ type = "", children, values = {} }) => {
  let ContentComponent = <></>

  if (type === "contact") {
    ContentComponent = (
      <>
        <span>E: </span> <a href={`mailto:${values.email}`}>{values.email}</a>
        <br />
        {/* <span> P: </span>{" "}
        <a href={`tel:${removeSpaces(values.number)}`}>{values.number}</a> */}
      </>
    )
  } else if (type === "sns") {
    ContentComponent = (
      <>
        <a href={values.facebook} target="_blank" rel="noopener noreferrer">
          Facebook
        </a>
        <br />
        <a href={values.linkedin} target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
      </>
    )
  } else if (type === "profile") {
    ContentComponent = (
      <>
        <span>{values.name}</span> <br />
        <span>E: </span> <a href={`mailto:${values.email}`}>{values.email}</a>
        <br />
        {/* <span> P: </span>{" "}
        <a href={`tel:${removeSpaces(values.number)}`}>{values.number}</a> */}
      </>
    )
  }

  return ContentComponent
}

// Content
const ContactPage = ({ data = {}, location }) => {
  let {
    contentNode: { values = "" },
  } = data
  values = JSON.parse(values)

  return (
    <LayoutV2 location={location} headerActive={`Expertise`}>
      <SEO title={values.title} keywords={values.keywords} />
      <article className="post-content page-template no-image animated fadeIn contact-page scroller-adjustment">
        <div className="post-content-body">
          <div>
            <HeroTitleDiv>
              <span
                className="p-color--psg-secondary"
                style={{ fontSize: `inherit` }}
              >
                Get in touch.
              </span>{" "}
              Let's work together.
            </HeroTitleDiv>
            <div className="row">
              <div className="col-sm-12">
                <CardList className={`contact-page-card-list`}>
                  {values.cards.map(
                    ({ title = "", type = "", values = {} }) => {
                      return (
                        <CardListItem cardTitle={title}>
                          <CardListItemContent type={type} values={values} />
                        </CardListItem>
                      )
                    }
                  )}
                </CardList>
              </div>
            </div>
          </div>
          <div>
            <ContactForm className="contact-form" />
          </div>
        </div>
      </article>
    </LayoutV2>
  )
}

const indexQuery = graphql`
  query {
    contentNode(identifier: { eq: "partial_contact" }) {
      identifier
      values
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage location={props.location} data={data} {...props} />
    )}
  />
)
