import axios from "axios"

const https = require("https")

const APIInstance = axios.create({
  // baseURL: urlBuilder(Config.api),
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
})

function Get(urlPath = "", callback) {
  APIInstance({
    method: "get",
    url: urlPath,
    data: {},
  })
    .then((result) => {
      return callback(null, result)
    })
    .catch((err) => {
      return callback(err)
    })
}

function Post({ urlPath = "", reqBody }, callback) {
  try {
    const result = axios.post(urlPath, reqBody)
    return callback(null, result)
  } catch (err) {
    return callback(err)
  }
}

function Put({ urlPath = "", reqBody }, callback) {
  // axios.put(`${urlBuilder(Config.api)}${urlPath}`, reqBody)
  // .then(response => {
  //     return callback(null, response);
  // })
  // .catch(error => {
  //     return callback(error);
  // });

  APIInstance({
    method: "put",
    url: urlPath,
    data: reqBody,
  })
    .then((result) => {
      return callback(null, result)
    })
    .catch((err) => {
      return callback(err)
    })
}

const GFuncRequest = {
  Get: Get,
  Post: Post,
  Put: Put,
}

export default GFuncRequest
